import React, { useState, useEffect, useRef } from "react";
import Pica from "pica";

import * as API from "../api";
import ApiKeyAdmin from "../components/apikey-admin";

function Settings({ jwt: jwtToken }) {
  const [images, setImages] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    fetchImages(jwtToken);
  }, [jwtToken]);

  const fetchImages = async (jwtToken) => {
    if (!jwtToken) return;
    const images = await API.getImages(jwtToken);
    setImages(images);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const uploadFile = async () => {
    if (!file) return;
    const pica = Pica();
    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = async () => {
      const canvas = document.createElement("canvas");
      const maxSideLength = 640; // Set the desired max side length for the resized image

      // Calculate the new size while keeping the aspect ratio
      const ratio = Math.min(
        maxSideLength / img.width,
        maxSideLength / img.height
      );
      const newWidth = img.width * ratio;
      const newHeight = img.height * ratio;

      canvas.width = newWidth;
      canvas.height = newHeight;

      // Use Pica to resize the image
      pica
        .resize(img, canvas)
        .then((result) => pica.toBlob(result, "image/webp"))
        .then(async (blob) => {
          // Now you have the resized image as a Blob, ready for uploading
          console.log(file.name);
          setUploading(true);
          try {
            const { url: presignedUrl, filename } = await API.getPresignedUrl(
              jwtToken,
              file.name
            );
            console.log("New File Name: ", filename);
            console.log("Presigned URL: ", presignedUrl);
            console.log(file.type);

            if (!filename) {
              alert("Filename not found.");
              return;
            }
            const response = await fetch(presignedUrl, {
              method: "PUT",
              body: blob,
              headers: {
                "Content-Type": "image/webp",
              },
            });
            console.log(response);
            if (response.ok) {
              setFile(null);
              setUploading(false);
              if (fileInputRef.current) {
                fileInputRef.current.value = "";
              }
              fetchImages();
            } else {
              alert("Upload failed.");
            }
          } catch (error) {
            console.error("There was an error uploading the file:", error);
          }
        });
    };
  };

  return (
    <div className="App px-8">
      <section className="max-w-4xl mx-auto mt-6">
        <h1 className="text-2xl text-slate-600 font-semibold my-4">Images</h1>
        <div className="pt-6">
          <input type="file" onChange={handleFileChange} ref={fileInputRef} />
          <button
            type="button"
            className="rounded bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={uploadFile}
            disabled={uploading}
          >
            Upload
          </button>
          {uploading && <p>Uploading...</p>}
          <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
            <h2 className="text-2xl font-bold tracking-tight text-gray-900">
              Your Images
            </h2>

            <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 xl:gap-x-8">
              {images.map((image) => (
                <div key={image.id} className="group relative">
                  <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75 ">
                    <img
                      src={`https://dream.img.in.net/${image.path}/${image.filename}`}
                      alt=""
                      className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                    />
                  </div>
                  <div className="mt-4 flex justify-between">
                    <h3 className="text-sm text-gray-700">
                      https://dream.img.in.net/{image.path}/{image.filename}
                    </h3>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <ApiKeyAdmin jwtToken={jwtToken} />
      </section>
    </div>
  );
}

export default Settings;
