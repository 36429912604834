import axios from "axios";

const isValidJwt = (jwt) => {
  const payload = JSON.parse(atob(jwt.split(".")[1]));
  const now = Date.now() / 1000;
  if (payload.exp < now) {
    return false;
  }
  return true;
};

export const getJwt = async () => {
  const jwt = localStorage.getItem("jwt");
  if (!jwt) {
    return false;
  }
  if (!isValidJwt(jwt)) {
    const loginCode = localStorage.getItem("loginCode");
    const error = await login(loginCode);
    if (error) {
      return false;
    }
    return localStorage.getItem("jwt");
  }
  return jwt;
};

export const createAccount = async () => {
  try {
    const response = await fetch("/auth/create-new-user");
    const data = await response.json();

    if (data.code) {
      //localStorage.setItem("jwt", data.token);
      //localStorage.setItem("loginCode", data.loginCode);
      return data.code;
    } else {
      return false;
    }
  } catch (error) {
    console.error("There was an error logging in:", error);
    return false;
  }
};

export const login = async (loginCode) => {
  try {
    const response = await fetch("/auth/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ code: loginCode }),
    });

    const data = await response.json();

    if (data) {
      localStorage.setItem("jwt", data.token);
      localStorage.setItem("loginCode", data.loginCode);
      return data.token;
    } else {
      return false;
    }
  } catch (error) {
    console.error("There was an error logging in:", error);
    return false;
  }
};

export const logout = () => {
  localStorage.removeItem("jwt");
  localStorage.removeItem("loginCode");
};

export const getPresignedUrl = async (jwtToken, filename) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${jwtToken}`,
  };
  const params = {
    filename,
  };
  const response = await axios.get("/api/get-upload-url", {
    params: params,
    headers: headers,
  });
  console.log(response);
  return response.data;
};

export const getImages = async (jwtToken) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${jwtToken}`,
  };

  const response = await axios.get("/api/get-images", {
    headers: headers,
  });

  return response.data;
};

export const getApiKey = async (jwtToken) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${jwtToken}`,
  };
  const response = await axios.get("/api/get-api-key", {
    headers: headers,
  });
  return response.data;
};

export const newApiKey = async (jwtToken) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${jwtToken}`,
  };
  const response = await axios.get("/api/new-api-key", {
    headers: headers,
  });
  return response.data;
};
