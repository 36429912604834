import React, { useState } from "react";
import * as API from "../api";

function CreateAccount() {
  const [data, setData] = useState("");

  const getUser = async () => {
    const data = await API.createAccount();
    setData(data);
  };

  return (
    <div className="App px-8">
      <section className="max-w-4xl mx-auto mt-6">
        <h1 className="text-2xl text-slate-600 font-semibold my-4">
          Create an account
        </h1>
        <p className="text-slate-500">
          When you create an account, you'll be able to save your words. We
          don't collect any of your personal information, so you just need to
          get a special code. We recommend to save it to your password manager
          to make it easier to retrieve. If you lose your code, you won't be
          able to access your data. Keep it safe!
        </p>
        <button
          className="bg-amber-400 text-gray-700 px-4 py-2 rounded my-4"
          onClick={getUser}
        >
          Get a login code
        </button>
        {data && (
          <>
            <p className="text-slate-500 mt-4">Your login code is: {data}</p>
            <button
              className="bg-amber-400 text-gray-700 px-4 py-2 rounded my-4"
              onClick={() => {
                navigator.clipboard.writeText(data);
              }}
            >
              Copy to Clipboard
            </button>
          </>
        )}
      </section>
    </div>
  );
}

export default CreateAccount;
