import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import * as API from "./api";

import Header from "./components/header";
import Home from "./pages/home";
import CreateAccount from "./pages/create-account";
import Login from "./pages/login";
import Settings from "./pages/settings";

export default function App() {
  const [jwt, setJwt] = useState(false);
  useEffect(() => {
    const handleJwt = async () => {
      const result = await API.getJwt();
      if (result) {
        setJwt(result);
      }
    };
    handleJwt();
  }, []);

  const handleLogout = () => {
    API.logout();
    setJwt(false);
  };

  const handleLogin = async (loginCode) => {
    const jwt = await API.login(loginCode);
    if (jwt) {
      setJwt(jwt);
      return true;
    }

    return false;
  };

  const routeList = [
    {
      name: "Home",
      path: "/",
      component: Home,
      nav: true,
      authRequired: false,
    },
    {
      name: "Create Account",
      path: "/create-account",
      component: CreateAccount,
      nav: true,
      authRequired: false,
    },
    {
      name: "Login",
      path: "/login",
      component: Login,
      nav: true,
      authRequired: false,
      props: { handleLogin },
    },
    {
      name: "Settings",
      path: "/settings",
      component: Settings,
      nav: true,
      authRequired: true,
      props: { jwt },
    },
  ];

  return (
    <Router>
      <div className="min-h-full flex flex-col">
        <Header
          routeList={routeList}
          logout={handleLogout}
          isLoggedIn={jwt ? true : false}
        />
        <div>
          <Routes>
            {routeList.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={<route.component {...route.props} />}
              />
            ))}
          </Routes>
        </div>
      </div>
    </Router>
  );
}
