import { useState, useEffect } from "react";
import * as API from "../api";

export default function ApiKeyAdmin({ jwtToken }) {
  const [apiKey, setApiKey] = useState("");

  useEffect(() => {
    fetchApiKey(jwtToken);
  }, [jwtToken]);

  const fetchApiKey = async (jwtToken) => {
    if (!jwtToken) return;
    const results = await API.getApiKey(jwtToken);
    setApiKey(results.api_key);
  };

  const generateApiKey = async () => {
    const results = await API.newApiKey(jwtToken);
    setApiKey(results.api_key);
  };

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          API Key
        </h3>
        <div className="mt-2 max-w-xl text-sm text-gray-500">
          <p>You can use this API key</p>
        </div>
        <form className="mt-5 sm:flex sm:items-center">
          <div className="w-full sm:max-w-xs">
            <label htmlFor="email" className="sr-only">
              Email
            </label>
            <input
              type="text"
              name="apikey"
              id="apikey"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="None"
              value={apiKey || "None"}
              disabled
            />
          </div>
          <button
            type="button"
            className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:ml-3 sm:mt-0 sm:w-auto"
            onClick={generateApiKey}
          >
            Generate New Key
          </button>
        </form>
      </div>
    </div>
  );
}
